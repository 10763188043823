import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Modal`}</h1>
    <p>{`The Modal component interrupts the
user without leaving the current page.
It forces the user to interact with
critical information.`}</p>
    <ComponentPreview componentName="modal--default-story" hasReact hasAngular hasHTML minHeight="25rem" titleAttr="Modal Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`A Modal should be used when there
is critical information to show the user,
a choice that the user must make immediately,
a small amount of user information is needed,
or when an action will result in a longer
than normal wait.`}</p>
    <p>{`The Modal component is inherently disruptive.
It disables everything on the page below it
until it is dismissed. The Modal component
should be used sparingly, thoughtfully, and
only when interruption is necessary to
continuing a task.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Ensure that messaging is helpful and provides
a clear path of action for the user.`}</li>
      <li parentName="ul">{`The content inside of a Modal should be short
and concise. If much more information is needed,
consider sending the user to a new page.`}</li>
      <li parentName="ul">{`A Modal should not be launched from within another Modal.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Choice`}</h3>
    <p>{`A Modal that asks a question and
allows a user to take an action.`}</p>
    <ComponentPreview componentName="modal--default-story" hasReact hasAngular hasHTML minHeight="25rem" titleAttr="Choice Modal Example" mdxType="ComponentPreview" />
    <h3>{`Info`}</h3>
    <p>{`A Modal that shows critical information to the user.`}</p>
    <ComponentPreview componentName="modal--info" hasReact hasAngular hasHTML minHeight="20rem" titleAttr="Info Modal Example" mdxType="ComponentPreview" />
    <h3>{`Wait`}</h3>
    <p>{`A Modal that acts as a loading screen.
Once loading is finished, it will dismiss itself.`}</p>
    <ComponentPreview componentName="modal--wait" hasReact hasAngular hasHTML minHeight="25rem" titleAttr="Wait Modal Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`A Modal (excluding the Wait Modal)
must have a `}<a parentName="li" {...{
          "href": "/using-spark/components/button"
        }}>{`Button`}</a>{` to trigger it.`}</li>
      <li parentName="ul">{`A Modal (excluding the Wait Modal)
must have a Close Icon to dismiss it.`}</li>
      <li parentName="ul">{`A Modal has a background mask that
covers up the page below.`}</li>
      <li parentName="ul">{`A Modal must have a title.`}</li>
      <li parentName="ul">{`A Modal must have informational text.`}</li>
      <li parentName="ul">{`A Choice Modal must include a `}<a parentName="li" {...{
          "href": "/using-spark/components/button"
        }}>{`Button`}</a>{`.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`The Modal component can be fully
controlled using a mouse, keyboard,
and/or screen reader.`}</li>
      <li parentName="ul">{`When a Modal is opened:`}<ul parentName="li">
          <li parentName="ul">{`The first focusable element
inside it will receive focus.`}</li>
          <li parentName="ul">{`The focus is "trapped" inside
the Modal. Pressing the Tab or Shift+Tab
keys to move focus will only shift
focus between the elements in the Modal.`}</li>
        </ul></li>
      <li parentName="ul">{`Modals can be closed several ways:`}<ul parentName="li">
          <li parentName="ul">{`Pressing the "Escape" key on the
keyboard (excluding Wait Modal)`}</li>
          <li parentName="ul">{`Pressing the Cancel button
(in the Choice Modal).`}</li>
          <li parentName="ul">{`Pressing the Close Icon
(in the Choice Modal or Info Modal).`}</li>
          <li parentName="ul">{`Pressing the background mask overlay
(in the Choice Modal or Info Modal).`}</li>
          <li parentName="ul">{`Wait Modal will close on its own.`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      